import React from "react";
import Modal from "../modal/Modal";
import { FaMapMarkerAlt } from "react-icons/fa";

const LocationModal = ({ isModalOpen, setIsModalOpen }) => {
  return (
    <Modal isOpen={isModalOpen} onClose={() => setIsModalOpen(false)}>
      <h3>
        Renovation Notice!
      </h3>
      <p className="quote">Our Honolulu location is currently CLOSED for renovations and will reopen this MAY!</p>
      <br />
      <p className="quote">Stay connected on social media for the latest updates!</p>
      <br />
      <p className="quote">Don’t forget to visit our <a className="linked" href="https://www.keikikingdomaiea.com/">Pearlridge Location</a> in the meantime!</p>

      
    </Modal>
  );
};

export default LocationModal;
