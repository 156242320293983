// import { useCookies } from "react-cookie";
import { Routes, Route, useLocation } from "react-router-dom";
import ScrollToTop from "./components/ScrollTop";
// import character from "./assets/character/HQ-Award.png";
import { useState } from "react";
import Home from "./components/home/Home";
import Navbar from "./components/navbar/Navbar";
// import Calendar from "./components/calendar/Calendar";
import Contact from "./components/contact/Contact";
// import Price from "./components/price/Price";
// import Rules from "./components/rules/Rules";
//import NotFound from "./components/404/NotFound";
import LocationModal from "./components/modal/LocationModal";

function App() {
  const location = useLocation();
  // const [cookies, setCookie] = useCookies(["has_visited"]);
  const [isModalOpen, setIsModalOpen] = useState(true);

  // useEffect(() => {
  //   setCookie("has_visited", true, { path: "/" });
  //   // Preload largest contentful page image
  //   const img = new Image();
  //   img.src = character;
  // }, [setCookie]);

  return (
    <>
      <Navbar />
      <ScrollToTop />
      <LocationModal isModalOpen={isModalOpen} setIsModalOpen={setIsModalOpen} />
      <Routes key={location.pathname} location={location}>
        <Route path="/" element={<Home setIsModalOpen={setIsModalOpen} />} />
        {/* <Route path="/calendar" element={<Calendar />} /> */}
        {/* <Route path="/price" element={<Price />} /> */}
        <Route path="/contact" element={<Contact />} />
        {/* <Route path="/rules" element={<Rules />} /> */}
        <Route path="*" element={<Home setIsModalOpen={setIsModalOpen} />} />
      </Routes>
    </>
  );
}

export default App;
